<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer  id="iiif-viewer"
                        [object]="object"
                        [searchable]="iiifSearchEnabled"
                        [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>
<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    {{'publication.page.titleprefix' | translate}}<ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
  </h2>
  <div class="pl-2">
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'publication.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!mediaViewer.image">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-thumbnail [thumbnail]="object?.thumbnail | async"></ds-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <ng-container *ngIf="mediaViewer.image">
      <ds-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-media-viewer>
    </ng-container>
    <ds-themed-item-page-file-section [item]="object"></ds-themed-item-page-file-section>
    <ds-generic-item-page-field
      [item]="object"
      [fields]="['dc.contributor.author']"
      [label]="'relationships.isAuthorOf' | translate">
    </ds-generic-item-page-field>
    <ds-related-items
      [parentItem]="object"
      [label]="'item.page.author' | translate"
      [relationType]="'isAuthorOfPublication'"
    ></ds-related-items>
    <ds-generic-item-page-field
      [item]="object"
      [fields]="['dc.contributor.advisor']"
      [label]="'relationships.isAdvisorOf' | translate">
    </ds-generic-item-page-field>
    <ds-related-items
      [parentItem]="object"
      [label]="'item.page.advisor' | translate"
      [relationType]="'isAdvisorOfPublication'"
    ></ds-related-items>
    <ds-generic-item-page-field 
    [item]="object" 
    [fields]="['dc.contributor.editor']" 
    [label]="'publication.page.editor-title' | translate">
    </ds-generic-item-page-field>
    <ds-related-items [parentItem]="object"
      [relationType]="'isOrgUnitOfPublication'"
      [label]="'relationships.isOrgUnitOf' | translate">
    </ds-related-items>
    <ds-related-items [parentItem]="object"
      [relationType]="'isSeriesOfPublication'"
      [label]="'relationships.isSeriesOfPublication' | translate">
    </ds-related-items>
    <ds-item-page-collections [item]="object"></ds-item-page-collections>
    <ds-item-page-uri-field [item]="object"
      [fields]="['dc.relation.issupplementto']"
      [label]="'publication.page.issupplementto'">
    </ds-item-page-uri-field>
    <!--<ds-generic-item-page-field [item]="object"
      [fields]="['journal.title']"
      [label]="'publication.page.journal-title'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['journal.identifier.issn']"
      [label]="'publication.page.journal-issn'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['journalvolume.identifier.name']"
      [label]="'publication.page.volume-title'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.publisher']"
      [label]="'publication.page.publisher'">
    </ds-generic-item-page-field>-->
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-item-page-uri-field [item]="object"
      [fields]="['dc.identifier.uri']"
      [label]="'item.page.uri'">
    </ds-item-page-uri-field>
    <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.description.sponsorship']"
      [label]="'publication.page.description.sponsorship'">
    </ds-generic-item-page-field>
    <ds-item-page-date-field [item]="object"></ds-item-page-date-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.format.extent']"
      [label]="'publication.page.extent'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.type']"
      [label]="'publication.page.type'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.type.genre']"
      [label]="'publication.page.type.genre'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.rights']"
      [label]="'publication.page.rights'">
    </ds-generic-item-page-field>
    <ds-item-page-uri-field [item]="object"
      [fields]="['dc.rights.uri']"
      [label]="'publication.page.rights.uri'">
    </ds-item-page-uri-field>
    <!--
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isProjectOfPublication'"
      [label]="'relationships.isProjectOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isJournalIssueOfPublication'"
      [label]="'relationships.isJournalIssueOf' | translate">
    </ds-related-items>

    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.subject']"
      [separator]="','"
      [label]="'item.page.subject'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.identifier.citation']"
      [label]="'item.page.citation'">
    </ds-generic-item-page-field>
    -->
    <div>
      <a class="btn btn-outline-primary" role="button" [routerLink]="[itemPageRoute + '/full']">
        <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
</div>
