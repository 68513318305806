import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Item } from '../../../../core/shared/item.model';
import { ViewMode } from '../../../../core/shared/view-mode.model';
import { ItemComponent } from '../../../../item-page/simple/item-types/shared/item.component';
import { listableObjectComponent } from '../../../../shared/object-collection/shared/listable-object/listable-object.decorator';

/**
 * Component that represents a publication Item page
 */

@listableObjectComponent('Series', ViewMode.StandalonePage)
@Component({
  selector: 'ds-series-item',
  styleUrls: ['./series-item.component.scss'],
  templateUrl: './series-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeriesComponent extends ItemComponent {

}
