<div class="row">
  <div *ngIf="showThumbnails" class="col-3 col-md-2">
    <ds-thumbnail [thumbnail]="item?.thumbnail | async" [limitWidth]="true">
    </ds-thumbnail>
  </div>
  <div [ngClass]="showThumbnails ? 'col-9' : 'col-md-12'">
    <div *ngIf="item" @fadeInOut>
      <ng-container *ngIf="status">
        <ds-mydspace-item-status [status]="status"></ds-mydspace-item-status>
      </ng-container>
      <div class="d-flex">
        <ds-type-badge [object]="item"></ds-type-badge>
      </div>
      <ds-truncatable [id]="item.id">
        <h3 [innerHTML]="item.firstMetadataValue('dc.title') || ('mydspace.results.no-title' | translate)"
            [ngClass]="{'lead': true,'text-muted': !item.firstMetadataValue('dc.title')}"></h3>
        <div>
      <span class="text-muted">
        <ds-truncatable-part [id]="item.id" [minLines]="1">
          (<span *ngIf="item.hasMetadata('dc.publisher')" class="item-list-publisher"
                 [innerHTML]="item.firstMetadataValue('dc.publisher') + ', '"></span>
          <span class="item-list-date"
                [innerHTML]="item.firstMetadataValue('dc.date.issued') || ('mydspace.results.no-date' | translate)"></span>)
          <span *ngIf="item.hasMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']);"
                class="item-list-authors">
              <span
                *ngIf="item.allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']).length === 0">{{'mydspace.results.no-authors' | translate}}</span>
              <span
                *ngFor="let author of item.allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']); let last=last;">
                <span [innerHTML]="author"><span [innerHTML]="author"></span></span>
                <span *ngIf="!last">; </span>
              </span>
          </span>

          </ds-truncatable-part>
      </span>

          <ds-truncatable-part [id]="item.id" [minLines]="1" class="item-list-abstract">
        <span [ngClass]="{'text-muted': !item.firstMetadataValue('dc.description.abstract')}"
              [innerHTML]="(item.firstMetadataValue('dc.description.abstract')) || ('mydspace.results.no-abstract' | translate)"></span>
          </ds-truncatable-part>

        </div>
      </ds-truncatable>
      <ds-item-submitter *ngIf="showSubmitter" [object]="object.indexableObject"></ds-item-submitter>
    </div>
  </div>
</div>
