<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer id="iiif-viewer" [object]="object" [searchable]="iiifSearchEnabled" [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>
<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    {{'series.page.titleprefix' | translate}}<ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])">
    </ds-metadata-values>
  </h2>
  <div class="pl-2">
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'publication.page.edit'">
    </ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <!--<ng-container *ngIf="!mediaViewer.image">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-thumbnail [thumbnail]="object?.thumbnail | async"></ds-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <ng-container *ngIf="mediaViewer.image">
      <ds-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-media-viewer>
    </ng-container>
    <ds-themed-item-page-file-section [item]="object"></ds-themed-item-page-file-section>
    <ds-item-page-date-field [item]="object"></ds-item-page-date-field>
    <ds-metadata-representation-list class="ds-item-page-mixed-author-field" [parentItem]="object" [itemType]="'Person'"
      [metadataFields]="['dc.contributor.author', 'dc.creator']" [label]="'relationships.isAuthorOf' | translate">
    </ds-metadata-representation-list>
    <ds-generic-item-page-field [item]="object" [fields]="['journal.title']" [label]="'publication.page.journal-title'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['journal.identifier.issn']"
      [label]="'publication.page.journal-issn'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['journalvolume.identifier.name']"
      [label]="'publication.page.volume-title'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['dc.publisher']" [label]="'publication.page.publisher'">
    </ds-generic-item-page-field>-->
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-item-page-uri-field [item]="object" [fields]="['dc.identifier.uri']" [label]="'item.page.uri'">
    </ds-item-page-uri-field>
    <ds-generic-item-page-field [item]="object" [fields]="['dc.type.series']" [label]="'series.page.type'"></ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['dc.description']" [label]="'publication.page.description'"></ds-generic-item-page-field>
    <ds-metadata-representation-list class="ds-item-page-mixed-author-field"
                                     [parentItem]="object"
                                     [itemType]="'OrgUnit'"
                                     [metadataFields]="['local.contributor.corporatename']"
                                     [label]="'relationships.isOrgUnitOf' | translate">
    </ds-metadata-representation-list>
    <ds-related-items [parentItem]="object" [relationType]="'isOrgUnitOfSeries'"
      [label]="'relationships.isOrgUnitOf' | translate">
    </ds-related-items>
    <div>
      <a class="btn btn-outline-primary" role="button" [routerLink]="[itemPageRoute + '/full']">
        <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search [item]="object" [relationTypes]="[{
                                          label: 'isSeriesOfPublication',
                                          filter: 'isSeriesOfPublication',
                                          configuration: 'publication'
                                        },
                                        {
                                          label: 'isSeriesOfArchivalMaterial',
                                          filter: 'isSeriesOfArchivalMaterial',
                                          configuration: 'archivalmaterial'
                                        }]">
    </ds-tabbed-related-entities-search>
  </div>
</div>
