import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Item } from '../../../../core/shared/item.model';
import { ViewMode } from '../../../../core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../shared/object-collection/shared/listable-object/listable-object.decorator';
import { ItemComponent } from '../../../../item-page/simple/item-types/shared/item.component';

/**
 * Component that represents a publication Item page
 */

@listableObjectComponent('ArchivalMaterial', ViewMode.StandalonePage)
@Component({
  selector: 'ds-archival-item',
  styleUrls: ['./archivalmaterial-item.component.scss'],
  templateUrl: './archivalmaterial-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArchivalMaterialComponent extends ItemComponent {

}
