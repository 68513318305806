<footer class="text-lg-start">
    <div class="row" data-scroll="visible">
      <div class="col-md-12 order-md-12">
      
        <div class="container-fluid footer-top-bar d-none d-lg-block">
        </div>
      
        <div id="gt-footer" class="container-fluid footer-bottom-bar">
          <div class="container pt-3">
            <div class="row footer-content">
      
              <div id="address_text" class="col-md-3 col-sm-12 my-2 order-md-1 order-2">
                <div class="region--footer_05__inner">
                  <div id="block-socialmedialinks" class="block-social-media-links">
      
      
      
      
                    <ul class="social-media-links--platforms platforms inline horizontal">
                      <li>
                        <a class="social-media-link-icon--youtube_channel"
                          href="https://www.youtube.com/channel/UCFkaWOGpyFBVRf5jEeD_wrA">
                          <span class="fab fa-youtube"></span>
                        </a>
      
                      </li>
                      <li>
                        <a class="social-media-link-icon--instagram" href="https://www.instagram.com/georgiatech">
                          <span class="fab fa-instagram"></span>
                        </a>
      
                      </li>
                      <li>
                        <a class="social-media-link-icon--linkedin"
                          href="https://www.linkedin.com/edu/school?id=18158&amp;amp%3Btrk=tyah">
                          <span class="fab fa-linkedin-in"></span>
                        </a>
      
                      </li>
                      <li>
                        <a class="social-media-link-icon--twitter" href="https://www.twitter.com/georgiatech">
                          <span class="fab fa-twitter"></span>
                        </a>
      
                      </li>
                      <li>
                        <a class="social-media-link-icon--facebook" href="https://www.facebook.com/georgiatech">
                          <span class="fab fa-facebook-f"></span>
                        </a>
      
                      </li>
                    </ul>
      
                  </div>
      
                  <div id="gt-contact-information" role="contentinfo" aria-labelledby="georgia tech contact information">
                    <h3>Georgia Institute of Technology</h3>
                    <p>North Avenue<br>Atlanta, GA 30332
                      <span itemprop="telephone">
                        <a class="gt-phone" href="tel: +14048942000" aria-label="telephone">
                          <i class="fas fa-phone-alt"></i>
                          +1 404.894.2000
                        </a>
                      </span>
                      <a class="gt-campus-map" href="https://map.gatech.edu/" aria-label="Campus Map">
                        <i class="fas fa-map-marker-alt"></i>
                        Campus Map
                      </a>
                    </p>
      
                  </div>
      
                </div>
      
      
              </div>
      
              <div class="col-md-3 col-sm-12 my-2 order-md-2 order-3">
                <div class="region--footer_06__inner">
      
                  <nav role="navigation" aria-labelledby="georgia tech general menu" id="block-gt-general-menu">
      
                    <ul>
                      <li>
                        <a href="" class="btn btn-primary btn-sm disabled" title="General">General</a>
                      </li>
                      <li>
                        <a href="https://directory.gatech.edu/" title="Directory">Directory</a>
                      </li>
                      <li>
                        <a href="https://careers.gatech.edu/" title="Employment">Employment</a>
                      </li>
                      <li>
                        <a href="https://gatech.edu/emergency/" title="Emergency Information">Emergency Information</a>
                      </li>
                    </ul>
      
      
      
                  </nav>
      
                </div>
      
      
              </div>
      
              <div class="col-md-3 col-sm-12 my-2 order-md-3 order-4">
                <div class="region--footer_07__inner">
      
                  <nav role="navigation" aria-labelledby="georgia tech legal menu" id="block-gt-legal-menu">
      
                    <ul>
                      <li>
                        <a href="" class="btn btn-primary btn-sm disabled" title="Legal">Legal</a>
                      </li>
                      <li>
                        <a href="https://gatech.edu/legal/" title="Legal &amp; Privacy Information">Legal &amp; Privacy
                          Information</a>
                      </li>
                      <li>
                        <a href="https://gbi.georgia.gov/human-trafficking-notice" title="Human Trafficking Notice">Human
                          Trafficking Notice</a>
                      </li>
                      <li>
                        <a href="https://titleix.gatech.edu/" title="Title IX/Sexual Misconduct">Title IX/Sexual
                          Misconduct</a>
                      </li>
                      <li>
                        <a href="https://osi.gatech.edu/hazing-conduct-history" title="Hazing Public Disclosures">Hazing
                          Public Disclosures</a>
                      </li>
                      <li>
                        <a href="https://gatech.edu/accessibility/" title="Accessibility">Accessibility</a>
                      </li>
                      <li>
                        <a href="https://gatech.edu/accountability/" title="Accountability">Accountability</a>
                      </li>
                      <li>
                        <a href="https://gatech.edu/accreditation/" title="Accreditation">Accreditation</a>
                      </li>
                    </ul>
      
      
      
                  </nav>
      
                </div>
      
      
              </div>
      
              <div id="gt-logo-footer" class="col-md-3 col-sm-12 my-2 order-md-4 order-1">
                <div class="region--footer_08__inner">
                  <div id="gt-copyright" aria-labelledby="georgia tech logo and copyright">
                    <div class="gt-footer-logo float-md-right float-sm-left">
                      <a href="https://www.gatech.edu/" title="Georgia Tech">
                        <img src="assets/images/gt-logo-oneline-white.svg" alt="Georgia Tech"
                          role="presentation" aria-label="logo link to main campus site">
                      </a>
                    </div>
      
                    <div class="gt-footer-copy d-none d-sm-block float-md-right float-sm-left">
                      <p class="p-1 mt-2 copyright-hide">© 2022 Georgia Institute of
                        Technology</p>
                    </div>
      
                    <div class="gt-footer-login mt-5 d-none d-sm-block float-md-right float-sm-left">
                      <p class="gt-login  d-sm-none d-md-block">
                        <a class="gt-login" href="https://theme.gatech.edu/cas" aria-label="admin login">
                          <i class="fas fa-sign-in-alt"></i>
                          GT LOGIN
                        </a>
                      </p>
                    </div>
                  </div>
      
      
      
                </div>
      
      
              </div>
      
            </div>
            <div class="row footer-bg-row">
              <div class="col-12 col-sm-12 col-md-9 footer-bg-col"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 order-md-1">
        <div class="d-block d-md-none button-bar">
          <button type="button" class="btn-footer w-100" (click)="isMenuCollapsed = !isMenuCollapsed">
            <i class="fas fa-bars"></i>
            Resources
          </button>
        </div>
        <div [ngbCollapse]="isMenuCollapsed" class="collapse dont-collapse-sm" id="collapseFooter">
          <div class="well">
            <div id="gt-superfooter" class=" container-fluid gt-black-wrapper">
              <div class="container pt-4 pb-4">
                <div class="row center-block" data-scroll="visible">
                  <div class="footer-first col-lg-3 col-md-3 col-sm-12 col-xs-12 column">
                    <div class="region--footer_01__inner">
                      <nav role="navigation" aria-labelledby="georgia tech footer section one menu"
                        id="block-gt-footer-one-links">
                        <ul>
                          <li>
                            <a href="" class="btn btn-primary btn-lg disabled" title="Georgia Tech Resources">Georgia Tech
                              Resources</a>
                          </li>
                          <li>
                            <a href="https://gatech.edu/offices-and-departments" title="Offices and Departments">Offices
                              and
                              Departments</a>
                          </li>
                          <li>
                            <a href="https://news.gatech.edu/" title="News Center">News Center</a>
                          </li>
                          <li>
                            <a href="https://calendar.gatech.edu/" title="Campus Calendar">Campus Calendar</a>
                          </li>
                          <li>
                            <a href="https://specialevents.gatech.edu/" title="Special Events">Special Events</a>
                          </li>
                          <li>
                            <a href="https://greenbuzz.gatech.edu/" title="GreenBuzz">GreenBuzz</a>
                          </li>
                          <li>
                            <a href="https://comm.gatech.edu/" title="Institute Communications">Institute
                              Communications</a>
                          </li>
                          <li>
                            <a href="" class="btn btn-primary btn-lg disabled" title="Visitor Resources">Visitor
                              Resources</a>
                          </li>
                          <li>
                            <a href="https://admission.gatech.edu/visit" title="Campus Visits">Campus Visits</a>
                          </li>
                          <li>
                            <a href="https://www.gatech.edu/about/visit" title="Directions to Campus">Directions to
                              Campus</a>
                          </li>
                          <li>
                            <a href="https://pts.gatech.edu/visitors" title="Visitor Parking Information">Visitor Parking
                              Information</a>
                          </li>
                          <li>
                            <a href="https://lawn.gatech.edu/gtvisitor" title="GT visitor Wireless Network Information">GT
                              visitor Wireless Network Information</a>
                          </li>
                          <li>
                            <a href="https://pe.gatech.edu/global-learning-center"
                              title="Georgia Tech Global Learning Center">Georgia Tech Global Learning Center</a>
                          </li>
                          <li>
                            <a href="https://gatechhotel.com/" title="Georgia Tech Hotel and Conference Center">Georgia
                              Tech
                              Hotel and Conference Center</a>
                          </li>
                          <li>
                            <a href="https://gatech.bncollege.com/shop/gatech/home"
                              title="Barnes and Noble at Georgia Tech">Barnes and Noble at Georgia Tech</a>
                          </li>
                          <li>
                            <a href="https://arts.gatech.edu/" title="Ferst Center for the Arts">Ferst Center for the
                              Arts</a>
                          </li>
                          <li>
                            <a href="https://paper.gatech.edu/" title="Robert C. Williams Paper Museum">Robert C. Williams
                              Paper Museum</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div class="footer-second col-lg-3 col-md-3 col-sm-12 col-xs-12 column">
                    <div class="region--footer_02__inner">
                      <nav role="navigation" aria-labelledby="georgia tech footer section two menu"
                        id="block-gt-footer-two-links">
                        <ul>
                          <li>
                            <a href="" class="btn btn-primary btn-lg disabled"
                              title="Colleges, Instructional Sites and Research">Colleges, Instructional Sites and
                              Research</a>
                          </li>
                          <li>
                            <a href="" class="btn btn-secondary btn-sm disabled" title="Colleges">Colleges</a>
                          </li>
                          <li>
                            <a href="https://cc.gatech.edu/" title="College of Computing">College of Computing</a>
                          </li>
                          <li>
                            <a href="https://design.gatech.edu/" title="College of Design">College of Design</a>
                          </li>
                          <li>
                            <a href="https://coe.gatech.edu/" title="College of Engineering">College of Engineering</a>
                          </li>
                          <li>
                            <a href="https://cos.gatech.edu/" title="College of Sciences">College of Sciences</a>
                          </li>
                          <li>
                            <a href="https://iac.gatech.edu/" title="Ivan Allen College of Liberal Art">Ivan Allen College
                              of Liberal Art</a>
                          </li>
                          <li>
                            <a href="https://scheller.gatech.edu/" title="Scheller College of Business">Scheller College
                              of
                              Business</a>
                          </li>
                          <li>
                            <a href="" class="btn btn-secondary btn-sm disabled" title="Instructional Sites">Instructional
                              Sites</a>
                          </li>
                          <li>
                            <a href="https://lorraine.gatech.edu/" title="Georgia Tech-Lorraine">Georgia Tech-Lorraine</a>
                          </li>
                          <li>
                            <a href="https://shenzhen.gatech.edu/" title="Georgia Tech-Shenzhen">Georgia Tech-Shenzhen</a>
                          </li>
                          <li>
                            <a href="https://pe.gatech.edu/georgia-tech-online" title="Georgia Tech Online">Georgia Tech
                              Online</a>
                          </li>
                          <li>
                            <a href="https://pe.gatech.edu/" title="Professional Education">Professional Education</a>
                          </li>
                          <li>
                            <a href="https://esl.gatech.edu/" title="The Language Institute">The Language Institute</a>
                          </li>
                          <li>
                            <a href="" class="btn btn-secondary btn-sm disabled" title="Global Footprint">Global
                              Footprint</a>
                          </li>
                          <li>
                            <a href="https://global.gatech.edu/" title="Global Engagement">Global Engagement</a>
                          </li>
                          <li>
                            <a href="" class="btn btn-secondary btn-sm disabled" title="Research">Research</a>
                          </li>
                          <li>
                            <a href="https://gtri.gatech.edu/" title="Georgia Tech Research Institute">Georgia Tech
                              Research
                              Institute</a>
                          </li>
                          <li>
                            <a href="https://research.gatech.edu/" title="Research at Georgia Tech">Research at Georgia
                              Tech</a>
                          </li>
                          <li>
                            <a href="https://research.gatech.edu/meet-dr-chaouki-t-abdallah"
                              title="Executive Vice President for Research">Executive Vice President for Research</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div class="footer-third col-lg-3 col-md-3 col-sm-12 col-xs-12 column">
                    <div class="region--footer_03__inner">
                      <nav role="navigation" aria-labelledby="georgia tech footer section three menu"
                        id="block-gt-footer-three-links">
  
                        <ul>
                          <li>
                            <a href="" class="btn btn-primary btn-lg disabled"
                              title="Student and Parent Resources">Student
                              and Parent Resources</a>
                          </li>
                          <li>
                            <a href="" class="btn btn-secondary btn-sm disabled" title="Student Resources">Student
                              Resources</a>
                          </li>
                          <li>
                            <a href="https://gatech.edu/admissions" title="Apply">Apply</a>
                          </li>
                          <li>
                            <a href="https://buzzport.gatech.edu/" title="BuzzPort">BuzzPort</a>
                          </li>
                          <li>
                            <a href="https://buzzcard.gatech.edu/" title="Buzzcard">Buzzcard</a>
                          </li>
                          <li>
                            <a href="https://career.gatech.edu/" title="Career Center">Career Center</a>
                          </li>
                          <li>
                            <a href="https://commencement.gatech.edu/" title="Commencement">Commencement</a>
                          </li>
                          <li>
                            <a href="https://library.gatech.edu/" title="Library">Library</a>
                          </li>
                          <li>
                            <a href="https://studentlife.gatech.edu" title="Student Life">Student Life</a>
                          </li>
                          <li>
                            <a href="https://create-x.gatech.edu/" title="Student Entrepreneurship">Student
                              Entrepreneurship</a>
                          </li>
                          <li>
                            <a href="https://oie.gatech.edu/study-abroad" title="Study Abroad">Study Abroad</a>
                          </li>
                          <li>
                            <a href="https://canvas.gatech.edu/" title="Canvas">Canvas</a>
                          </li>
                          <li>
                            <a href="" class="btn btn-secondary btn-sm disabled" title="Parent Resources">Parent
                              Resources</a>
                          </li>
                          <li>
                            <a href="https://parents.gatech.edu/" title="Parent and Family Programs">Parent and Family
                              Programs</a>
                          </li>
                          <li>
                            <a href="https://studentlife.gatech.edu" title="Division of Student Life">Division of Student
                              Life</a>
                          </li>
                          <li>
                            <a href="https://finaid.gatech.edu/" title="Scholarships and Financial Aid">Scholarships and
                              Financial Aid</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div class="footer-four col-lg-3 col-md-3 col-sm-12 col-xs-12 column">
                    <div class="region--footer_04__inner">
                      <nav role="navigation" aria-labelledby="georgia tech footer section four menu"
                        id="block-gt-footer-four-links">
  
                        <ul>
                          <li>
                            <a href="" class="btn btn-primary btn-lg disabled"
                              title="Employee, Alumni, and Other Resources">Employee, Alumni, and Other Resources</a>
                          </li>
                          <li>
                            <a href="" class="btn btn-secondary btn-sm disabled" title="Employees">Employees</a>
                          </li>
                          <li>
                            <a href="https://af.gatech.edu/" title="Administration and Finance">Administration and
                              Finance</a>
                          </li>
                          <li>
                            <a href="https://advising.gatech.edu/" title="Advising and Teaching">Advising and Teaching</a>
                          </li>
                          <li>
                            <a href="https://faculty.gatech.edu/" title="Faculty Affairs">Faculty Affairs</a>
                          </li>
                          <li>
                            <a href="https://careers.gatech.edu/" title="Faculty Hiring">Faculty Hiring</a>
                          </li>
                          <li>
                            <a href="https://ohr.gatech.edu/" title="Human Resources">Human Resources</a>
                          </li>
                          <li>
                            <a href="https://techworks.gatech.edu/" title="TechWorks">TechWorks</a>
                          </li>
                          <li>
                            <a href="" class="btn btn-secondary btn-sm disabled" title="Alumni">Alumni</a>
                          </li>
                          <li>
                            <a href="https://gtalumni.org/" title="Alumni Association">Alumni Association</a>
                          </li>
                          <li>
                            <a href="https://gtalumni.org/career" title="Alumni Career Services">Alumni Career
                              Services</a>
                          </li>
                          <li>
                            <a href="https://securelb.imodules.com/s/1481/alumni/19/interior.aspx?sid=1481&amp;gid=21&amp;pgid=787&amp;cid=1639&amp;appealcode=ALMW21Q105305S017UD"
                              title="Giving Back to Tech">Giving Back to Tech</a>
                          </li>
                          <li>
                            <a href="" class="btn btn-secondary btn-sm disabled" title="Outreach">Outreach</a>
                          </li>
                          <li>
                            <a href="https://atdc.org/" title="Startup Companies">Startup Companies</a>
                          </li>
                          <li>
                            <a href="https://innovate.gatech.edu/" title="Economic Development">Economic Development</a>
                          </li>
                          <li>
                            <a href="https://industry.gatech.edu/" title="Industry Engagement">Industry Engagement</a>
                          </li>
                          <li>
                            <a href="https://gov.gatech.edu/" title="Institute Relations">Institute Relations</a>
                          </li>
                          <li>
                            <a href="https://pe.gatech.edu/" title="Professional Education">Professional Education</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
</footer>
