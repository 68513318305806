import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Context } from '../../../../../../../app/core/shared/context.model';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { OrgUnitComponent as BaseComponent } from '../../../../../../../app/entity-groups/research-entities/item-pages/org-unit/org-unit.component';

/**
 * Component that represents a publication Item page
 */

@listableObjectComponent('OrgUnit', ViewMode.StandalonePage, Context.Any, 'dspace')
@Component({
  selector: 'ds-orgunit-item',
  styleUrls: ['./orgunit-item.component.scss'],
  templateUrl: './orgunit-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgUnitComponent extends BaseComponent {

}
