<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    {{'orgunit.page.titleprefix' | translate}}<ds-metadata-values
      [mdValues]="object?.allMetadata(['organization.legalName'])"></ds-metadata-values>
  </h2>
  <div class="pl-2">
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'orgunit.page.edit'">
    </ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-item-page-uri-field [item]="object" [fields]="['dc.identifier.uri']" [label]="'item.page.uri'">
    </ds-item-page-uri-field>
    <ds-generic-item-page-field [item]="object" [fields]="['dc.identifier.ror']" [label]="'item.page.ror'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['dc.description']" [label]="'publication.page.description'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['organization.previousName']"
      [label]="'item.page.previousname'">
    </ds-generic-item-page-field>
    <ds-metadata-representation-list class="ds-item-page-mixed-author-field"
                                     [parentItem]="object"
                                     [itemType]="'OrgUnit'"
                                     [metadataFields]="['local.relation.isparentoforgunit']"
                                     [label]="'relationships.isParentOfOrgUnit' | translate">
    </ds-metadata-representation-list>
    <ds-related-items [parentItem]="object" [relationType]="'isParentOfOrgUnit'"
                      [label]="'relationships.isParentOfOrgUnit' | translate">
    </ds-related-items>
    <ds-related-items [parentItem]="object" [relationType]="'isChildOfOrgUnit'"
                      [label]="'relationships.isChildOfOrgUnit' | translate">
    </ds-related-items>
    <ds-item-page-uri-field [item]="object" [fields]="['dc.contributor.archivesspaceAgent']"
      [label]="'item.page.archivesspaceAgent'">
    </ds-item-page-uri-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search [item]="object" [relationTypes]="[{
                                          label: 'isOrgUnitOfPublication',
                                          filter: 'isOrgUnitOfPublication',
                                          configuration: 'publication'
                                        },
                                        {
                                          label: 'isOrgUnitOfArchivalMaterial',
                                          filter: 'isOrgUnitOfArchivalMaterial',
                                          configuration: 'archivalmaterial'
                                        },
                                        {
                                          label: 'isOrgUnitOfPerson',
                                          filter: 'isOrgUnitOfPerson',
                                          configuration: 'person'
                                        },
                                        {
                                          label: 'isOrgUnitOfSeries',
                                          filter: 'isOrgUnitOfSeries',
                                          configuration: 'series'
                                        }]">
    </ds-tabbed-related-entities-search>
  </div>
</div>
