import { ArchivalMaterialComponent } from './app/item-page/simple/item-types/archival_material/archivalmaterial-item.component';
import { OrgUnitComponent } from './app/item-page/simple/item-types/orgunit/orgunit-item.component';
import { PersonComponent } from './app/item-page/simple/item-types/person/person-item.component';
import { PublicationComponent } from './app/item-page/simple/item-types/publication/publication.component';
import { SeriesComponent } from './app/item-page/simple/item-types/series/series-item.component';
import { UntypedItemComponent } from './app/item-page/simple/item-types/untyped-item/untyped-item.component';

export const ENTRY_COMPONENTS = [
    PublicationComponent,
    UntypedItemComponent,
    ArchivalMaterialComponent,
    OrgUnitComponent,
    PersonComponent,
    SeriesComponent,
];
